/**
 * Inverts a given hexadecimal color and adjusts its brightness based on luminance.
 *
 * @param {string} hex - The hexadecimal color code to invert (e.g., "#RRGGBB").
 * @returns {string} - The inverted and adjusted hexadecimal color code.
 *
 * @example
 * Returns a brighter inverted color if the luminance is low.
 * invertColorAndDarken("#123456"); // "#EDCBA9"
 *
 * @description
 * This function takes a hexadecimal color code, inverts its RGB values, and adjusts
 * the brightness based on the luminance of the inverted color. If the luminance is
 * below 0.5, the color is brightened; otherwise, it is slightly darkened.
 */
export function invertColorAndDarken(hex) {
    hex = hex.replace(/^#/, '');

    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    // Invert the color
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    // Calculate the luminance of the inverted color
    let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    // If the luminance is still low, brighten the color further
    let adjustmentFactor = luminance < 0.5 ? 1.2 : 0.8;

    r = Math.min(255, Math.floor(r * adjustmentFactor));
    g = Math.min(255, Math.floor(g * adjustmentFactor));
    b = Math.min(255, Math.floor(b * adjustmentFactor));

    // Convert the values to hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

/**
 * Determines a contrasting color (black or white) for a given hexadecimal color.
 * 
 * @param {string} hex - The hexadecimal color code (e.g., "#FFFFFF" or "FFFFFF").
 *                        The "#" prefix is optional.
 * @returns {string} - Returns "#000000" (black) if the input color is light, 
 *                     or "#FFFFFF" (white) if the input color is dark.
 */
export function getContrastingColor(hex) {
    hex = hex.replace(/^#/, '');

    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    // Calculate relative luminance using the standard formula
    let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    // If luminance is greater than 0.5 (light color), return black; otherwise, return white.
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
}