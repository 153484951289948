import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import useAuth from '../../CustomHooks/useAuth';
import { CardElementComponent } from '../UtilsComponents/CardElementComponent';
import { Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDashboardContext } from '../../CustomHooks/useDashboardContext';

const COLORS = ['#9793c6 ', '#b8affb ', '#cfc8fd ', '#5a5480', '#877be0  ', '#6d61c8  '];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {
                (percent * 100) < 1 ? "" :
                    `${(percent * 100).toFixed(0)}%`
            }
        </text>
    );
};

export const SurveyOrigin = () => {
    const axiosPrivate = useAxiosPrivate();
    const { initDate, endDate } = useDashboardContext();
    const { defaultCustomer } = useAuth();
    const getData = async () => {
        const response = await axiosPrivate.get(`dashboard/origin?customer=${defaultCustomer}&initDate=${initDate}&endDate=${endDate}`);
        return response.data;
    }
    const waitTime = 60 * 10 * 1000; // 10 minutes
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["pie", initDate, defaultCustomer],
        queryFn: getData,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: waitTime,
        cacheTime: waitTime,
    });

    return (
        <CardElementComponent sx={{ height: "246px" }} >
            <h2 className='text-center MediumMadeTommy' style={{ color: "#2b364a" }}>
                <FormattedMessage id="dashboard.survey.origin.pie" />
            </h2>
            {(isLoading || isError) ?
                <Skeleton animation="wave" variant="rounded" height={180} />
                :
                (isError) ?
                    error?.response?.status === 404 ?
                        <>
                            <h2 className='text-center RegularMadeTommy' style={{ color: "orange" }}>
                                <FormattedMessage id="alert.title" />
                            </h2>
                            <h6 className='text-center RegularMadeTommy'>
                                <FormattedMessage id="dashboard.survey.origin.no.data" />
                            </h6>
                        </>
                        :
                        <>
                            <h2 className='text-center RegularMadeTommy' style={{ color: "red" }}>
                                Error
                            </h2>
                            <h5 className='text-center RegularMadeTommy'>
                                <FormattedMessage id="dashboard.survey.origin.server.error" />
                            </h5>
                        </>
                    :
                    <PieChart width={300} height={200}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={90}
                            fill="#8884d8"
                            dataKey="value"
                            className='RegularMadeTommy'
                            paddingAngle={1}

                        >
                            {data?.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    cornerRadius={8}
                                />
                            ))}
                        </Pie>
                        <Legend
                            layout='vertical'
                            verticalAlign='middle'
                            iconType='circle'
                            align='right'
                            wrapperStyle={{ fontFamily: 'Regular MadeTommy', fontSize: '14px' }}
                        />
                        <Tooltip />
                    </PieChart>
            }
        </CardElementComponent>
    );
}
