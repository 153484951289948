import { FormattedMessage } from 'react-intl'
import { Link, NavLink } from 'react-router-dom'
import { Routes } from '../../Rutas/Routes'
import MetaDecorator from '../UtilsComponents/MetaDecorator'
import { SelectLanguage } from './SelectLanguage'
import useAuth from '../../CustomHooks/useAuth'
import FeedIcon from '@mui/icons-material/Feed';
import MessageIcon from '@mui/icons-material/Message';
import StoreIcon from '@mui/icons-material/Store';
import { useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { MenuComponent } from './MenuComponent'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { ROLES_LIST } from '../../Domains/roleList'
import { checkArrayCommonValues } from '../SurveyComponents/UtilsComponents/UtilsFunctions'

export const Sidebar = ({ element }) => {
    const matches = useMediaQuery('(min-width:1000px)');
    const { UserInfo, defaultCustomer } = useAuth();
    const activeFontColor = "rgb(98, 92, 151)" ;
    const routes = Routes
    document.body.style.backgroundColor = "#f2f1f7";

    useEffect(() => {
        if (!matches) {
            //check if the side menu is open
            const sideMenu = document.querySelector(".wui-side-menu");
            if (sideMenu.classList.value.includes("open")) {
                sideMenu.classList.toggle("open");
            }
        } else {
            const sideMenu = document.querySelector(".wui-side-menu");
            if (sideMenu.classList.value.includes("open")) {
                sideMenu.classList.toggle("open");
            }
        }
    }, [matches])


    const moveArrow = (e) => {
        const arrow = e.target
        //Solo funciona si la fecha esta de primero
        if (!arrow.classList.value.includes("fa-angle-right")) {
            if (arrow.children[0].ariaExpanded === "true") {
                arrow.children[0].style.transform = "rotate(90deg)"
                arrow.children[0].style.transition = "all 0.5s"
                arrow.children[0].ariaExpanded = "false"
            } else {
                arrow.children[0].style.transform = "rotate(0deg)"
                arrow.children[0].ariaExpanded = "true"
            }
        } else {
            if (arrow.ariaExpanded === "true") {
                arrow.style.transform = "rotate(90deg)"
                arrow.style.transition = "all 0.5s"
                arrow.ariaExpanded = "false"
            } else {
                arrow.style.transform = "rotate(0deg)"
                arrow.ariaExpanded = "true"
            }
        }
    }

    const userRoutes = [
        // {
        //     path: "/dashboard/customerstyles",
        //     name:
        //         <FormattedMessage
        //             id="change.customer.styles.title"
        //         />,
        //     icon: <ColorLensIcon className='MenuIcon' />,
        //     roles: [ROLES_LIST.Admin, ROLES_LIST.customer_admin, ROLES_LIST.Editor],
        // },
        {
            path: "/dashboard/mysurveys",
            name:
                <FormattedMessage
                    id="dashboard.my.surveys.btn"
                    defaultMessage="Mis encuestas"
                />,
            icon: <FeedIcon className='MenuIcon' />
        },
        {
            path: "/dashboard/mystores",
            name: <FormattedMessage
                id="navbar.my.stores"
                defaultMessage="Mis tiendas"
            />,
            icon: <StoreIcon className='MenuIcon' />
        },
        {
            path: "/dashboard/communications",
            name:
                <FormattedMessage
                    id="dashboard.shipping.methods.surveys.btn"
                    defaultMessage="Metodos de envio"
                />,
            icon: <MessageIcon className='MenuIcon' />,
            roles: [ROLES_LIST.Admin, ROLES_LIST.customer_admin],
        }
    ]

    //Collapse the side menu when clicking outside of it
    document.addEventListener("click", (e) => {
        //get parent element
        const parent = e.target.parentElement;
        const notCollapseMatches = !(e.target?.classList.value.includes("wui-side-menu-item")
            || e.target?.classList.value.includes('wui-side-menu-trigger')
            || e.target?.classList.value.includes('fa-bars')
            || e.target?.classList.value.includes('MenuIcon')
        )
        const notCollapseMatchesParent = !(parent?.classList.value.includes("wui-side-menu-item")
            || parent?.classList.value.includes('wui-side-menu-trigger')
            || parent?.classList.value.includes('fa-bars')
            || parent?.classList.value.includes('MenuIcon')
        )
        if (e.target.classList.value.includes('wui-side-menu-pin-trigger')) return;
        if (document.querySelector(".pinned")) return;
        if (notCollapseMatches && notCollapseMatchesParent) {
            const sideMenu = document.querySelector(".wui-side-menu");
            if (sideMenu?.classList.value.includes("open")) {
                sideMenu.classList.toggle("open");
            }
        }
    })

    return (
        <div>
            <MetaDecorator
                title={`Dashboard - ${defaultCustomer}`}
                description={"Dashboard"}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"Dashboard"}
                path={window.location.pathname}
            />
            <div className="wui-side-menu">
                <div className="wui-side-menu-header">
                    <div className="wui-side-menu-trigger" onClick={() => {
                        const sideMenu = document.querySelector(".wui-side-menu");
                        sideMenu.classList.toggle("open");
                    }}>
                        <i className="fa fa-bars" />
                    </div>
                    <div className="wui-side-menu-pin-trigger" onClick={() => {
                        const sideMenu = document.querySelector(".wui-side-menu");
                        sideMenu.classList.toggle("pinned");
                    }}>
                        <i className="fa fa-thumb-tack" />
                    </div>
                </div>
                <ul className="wui-side-menu-items">
                    <li>
                        <Link to={"/dashboard"} className="wui-side-menu-item rounded">
                            <i className="box-ico fa fa-home fa-fw" style={{color:"black"}}/>
                            <span className="box-title">
                                <FormattedMessage
                                    id="navbar.home"
                                    defaultMessage="Inicio"
                                />
                            </span>
                        </Link>
                    </li>
                    <li>
                        {UserInfo?.roles.some(rol => rol === 1) &&
                            <Link className="wui-side-menu-item align-items-center rounded collapsed" id='HomeArrow' onClick={moveArrow} data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false">
                                <i className="box-ico fa fa-angle-right fa-fw" aria-expanded="true"></i>
                                Admin Menu
                            </Link>
                        }
                        <div className="collapse" id="home-collapse">
                            <ul className="btn-toggle-nav  list-unstyled fw-normal pb-1 small">
                                {routes.map((route, i) => (
                                    (route?.protected) &&
                                    (UserInfo?.roles.some(rol => rol === route?.role)) &&
                                    <li className="nav-item rounded wui-side-menu-items" key={i} >
                                        <NavLink className="nav-link" to={`/dashboard${route.path}`}
                                            style={({ isActive }) => ({ color: isActive ? activeFontColor : "black"  })}>
                                            {route.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {UserInfo?.roles.some(rol => rol === 13) &&
                            <Link to={"/dashboard/users"} className="wui-side-menu-item rounded">
                                <SupervisedUserCircleIcon className='MenuIcon' /> <FormattedMessage
                                    id="navbar.users"
                                    defaultMessage="Usuarios"
                                />
                            </Link>
                        }
                    </li>
                    <li>
                        <Link className="wui-side-menu-item align-items-center rounded collapsed" id='HomeArrow' onClick={moveArrow} data-bs-toggle="collapse" data-bs-target="#survey-collapse" aria-expanded="false">
                            <i className="box-ico fa fa-angle-right fa-fw" aria-expanded="true"></i>
                            <FormattedMessage
                                id="survey"
                                defaultMessage="Encuestas"
                            />
                        </Link>
                        <div className="collapse" id="survey-collapse">
                            <ul className="btn-toggle-nav  list-unstyled fw-normal pb-1 small">
                                {
                                    userRoutes.map((route, i) => (
                                        (route?.roles) ?
                                            (checkArrayCommonValues(route.roles, UserInfo.roles)) &&
                                            <li className="nav-item rounded wui-side-menu-items rounded" key={i} >
                                                <NavLink className="nav-link" to={`${route.path}`}
                                                    style={({ isActive }) => ({ color: isActive ? activeFontColor : "black", fontWeight: "bold" })}>
                                                    {route.icon} &nbsp;
                                                    {route.name}
                                                </NavLink>
                                            </li>
                                            :
                                            <li className="nav-item rounded wui-side-menu-items rounded" key={i} >
                                                <NavLink className="nav-link" to={`${route.path}`}
                                                    style={({ isActive }) => ({ color: isActive ? activeFontColor : "black", fontWeight: "bold" })}>
                                                    {route.icon} &nbsp;
                                                    {route.name}
                                                </NavLink>
                                            </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to={"/dashboard/settings"} className="wui-side-menu-item rounded">
                            <i className="box-ico fa fa-cog fa-fw" />
                            <span className="box-title">
                                <FormattedMessage
                                    id="dashboard.settings"
                                    defaultMessage="Configuración"
                                />
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="wui-content ">
                <div className="wui-content-header shadow"  style={{ borderRadius: "20px", marginTop: "7px" }}>
                    <nav className="navbar navbar-expand-lg bg-light" style={{ borderRadius: "20px", marginTop: "7px" }}>
                        <div className="container-fluid">
                            <div className="wui-side-menu-trigger" onClick={() => {
                                const sideMenu = document.querySelector(".wui-side-menu");
                                sideMenu.classList.toggle("open");
                            }}
                                style={{ color: "black", margin: "-5px -2px -9px -20px", padding: "0px", }}
                            >
                                <i className="fa fa-bars" style={{ color: "black" }} />
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" style={{ color: "black" }}>...</span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav" >
                                <ul className="navbar-nav me-auto  mb-lg-0">
                                    <div className="nav-wrapper" style={{ width: "90px" }}>
                                        <SelectLanguage styles={{ width: "90px", color: "#6a6a6a" }} />
                                    </div>
                                </ul>
                                <div className='' style={{ textAlign: "center" }}>
                                    <span className="navbar-text me-3 me text-uppercase RegularMadeTommy" style={{ color: "black" }}>
                                        <FormattedMessage
                                            id="navbar.welcome"
                                            defaultMessage="Bienvenido"
                                        /> &nbsp;
                                        <b className='MediumMadeTommy'>{UserInfo?.email.split("@")[0]}</b>
                                    </span>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <MenuComponent />
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="wui-content-main">
                    {/* ---------------------------------------------- Main Content--------------------------------------------------- */}
                    {/* <p>Main content goes here</p> */}
                    <div className="row my-2">
                        {element}
                    </div>
                    {/* --------------------------------------------------------------------------------------------------------------------- */}
                </div>
            </div>
            <div className="wui-overlay" />
        </div>
    )
}
