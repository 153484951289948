import { SelectLanguage } from "./SelectLanguage";
import { getContrastingColor } from "../UtilsComponents/GetInvertColor";

export const MainFooter = (props) => {
    const { backColor } = props;
    var year = new Date().getFullYear();
    const backColorInverted = getContrastingColor(backColor ?? "#051B29");

    return (
        <div className="footer-basic" style={{ backgroundColor: backColor }}>
            <footer>
                <div className="social" style={{ padding: "0px 0% 0px 0%" }}>
                    <a target="blank" href="https://www.instagram.com/7.bytes/">
                        <i className="fa fa-instagram" style={{ color: backColorInverted }} />
                    </a>
                    <a target="blank" href="https://www.facebook.com/7Bytes.co">
                        <i className="fa fa-facebook" style={{ color: backColorInverted }} />
                    </a>
                    <a target="blank" href="https://www.youtube.com/channel/UCY8a1Mk7voJKgnTgUrNcgdw">
                        <i className="fa fa-youtube" style={{ color: backColorInverted }} />
                    </a>
                    {/* <a target="blank" href="https://wa.link/d9qeok">
                        <i className="fa fa-whatsapp" style={{ color: backColorInverted }} />
                    </a> */}
                    <div style={{
                        position: "absolute",
                        right: 15,  // Distance from the right side
                        bottom: "8%",
                        transform: "translateY(-50%)",  // Ensures vertical centering
                    }}>
                        <SelectLanguage styles={{ menuPlacement: "top", color: backColorInverted }} />
                    </div>
                </div>
                <p className="copyright RegularMadeTommy" style={{ color: backColorInverted }}>OCXCI - 7Bytes © {year}</p>
            </footer>
        </div>
    );
};