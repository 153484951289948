import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "../../CustomHooks/useAuth";
import useAxiosPrivate from "../../CustomHooks/useAxiosPrivate";
import { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import AddIcon from '@mui/icons-material/Add';
import { TableLanguages } from "../../Languages/TableLanguages";
import { TableLoadingSkeleton } from "../ReuseComponents/TableLoadingSkeleton";
import { FormattedMessage, useIntl } from "react-intl";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { CreateStoreDialog } from "./CreateStoreDialog";


export const MyStoresTable = () => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const axiosPrivate = useAxiosPrivate();
    const { defaultCustomer, isViewRole } = useAuth();
    const actionLanguage = TableLanguages();
    const [openCreate, setOpenCreate] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableChange, setTableChange] = useState(false);

    const getStoreData = async () => {
        const response = await axiosPrivate.get(`/store/all?customer=${defaultCustomer}`);
        return response.data;
    }
    const { data: myStores, isLoading, error } = useQuery({
        queryKey: ["MyStores", defaultCustomer],
        queryFn: getStoreData,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const deleteStoreMutation = useMutation({
        mutationFn: async (values) => {
            setTableChange(!tableChange);
            const response = await axiosPrivate.delete(`/store?customer=${defaultCustomer}`, {
                data: values
            });
            return response.data;
        },
        onSuccess: (data, values) => {
            alert(intl.formatMessage({ id: "store.delete.success" }));
            setOpenCreate(false);
            queryClient.invalidateQueries(["MyStores", defaultCustomer], { exact: true });
        },
        onError: (error) => {
            console.error(error);
            alert(intl.formatMessage({ id: "store.delete.error" }));
        },
        onSettled: () => {
            setTableChange(!tableChange);
        }
    });

    const columnsName = useMemo(() => ({
        "store_code": {
            header: intl.formatMessage({ id: "survey.source.name" }),
            Header: <Tooltip arrow placement="top" title={"Codigo de la tienda"}>
                <span>
                    <FormattedMessage id="store.table.code" />
                </span>
            </Tooltip>
        },
        "address": {
            header: intl.formatMessage({ id: "survey.source" }),
            Header: <Tooltip arrow placement="top" title={"Direccion de la tienda"}>
                <span>
                    <FormattedMessage id="store.table.address" />
                </span>
            </Tooltip>
        },
        "country_code": {
            header: intl.formatMessage({ id: "survey.visible.questions" }),
            Header: <Tooltip arrow placement="top" title={"Codigo del pais"}>
                <span>
                    <FormattedMessage id="store.table.country.code" />
                </span>
            </Tooltip>
        },
        "city": {
            header: intl.formatMessage({ id: "survey.visible.questions" }),
            Header: <Tooltip arrow placement="top" title={"Ciudad donde esta ubicada la tienda"}>
                <span>
                    <FormattedMessage id="store.table.city" />
                </span>
            </Tooltip>
        },
        "name": {
            header: intl.formatMessage({ id: "survey.enabled" }),
            Header: <Tooltip arrow placement="top" title={"Nombre de la tienda"}>
                <span className="text-center">
                    <FormattedMessage id="store.table.name" />
                </span>
            </Tooltip>
        },
        "place_id": {
            header: intl.formatMessage({ id: "answered.number" }),
            Header: <Tooltip arrow placement="top" title={"Lugar de reseña"}>
                <span>
                    <FormattedMessage id="store.table.review" />
                </span>
            </Tooltip>
        },
    }), [intl]);

    const columns = useMemo(() => {
        if (myStores?.length > 0) {
            // Get the keys of the first object in the array
            const keys = Object.keys(myStores[0]);
            const filteredKeys = keys.filter((key) => key !== "store_code" && key !== "id");

            // Map the filtered keys to column objects
            return filteredKeys.map((key) => ({
                header: columnsName[key]?.header,
                Header: columnsName[key]?.Header,
                accessorKey: key,
                Cell: ({ cell }) => {
                    if (key === "place_id") {
                        if (cell.getValue() === null) {
                            return <span>N/A</span>
                        }
                        else if (cell.getValue()?.includes("https://www.tripadvisor.es/UserReviewEdit-")) {
                            return (
                                <a style={{ color: "blue", textDecoration: "underline" }} href={cell.getValue()} target="_blank" rel="noreferrer">
                                    Tripadvisor
                                </a>
                            );
                        } else {
                            return (
                                <a style={{ color: "blue", textDecoration: "underline" }} href={`https://search.google.com/local/writereview?placeid=${cell.getValue()}`} target="_blank" rel="noreferrer">
                                    Google
                                </a>
                            );
                        }
                    } else {
                        return <span>{cell.getValue()}</span>;
                    }
                }
            }));
        } else {
            return [];
        }
        //remove store_code 
    }, [myStores, columnsName]);

    const handleDelete = (row) => {
        if (window.confirm(intl.formatMessage({ id: "store.delete.validation" })))
        deleteStoreMutation.mutate({ storeId: row.original.id });
    }

    return (
        <div className='mb-3'>
            {(isLoading) ?
                <TableLoadingSkeleton isLoading={isLoading} errorMsg={error} />
                :
                <>
                    <CreateStoreDialog 
                    setOpenCreate={setOpenCreate} 
                    openCreate={openCreate} 
                    row={selectedRow} 
                    setTableChange={setTableChange}
                    />
                    <MaterialReactTable
                        columns={columns}
                        data={myStores[0].store_code === 'No hay datos' ? [] : myStores}
                        enablePinning
                        localization={actionLanguage}
                        initialState={{
                            pagination: {
                                pageSize: 12,
                            },
                            density: 'compact'
                        }}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [2, 5, 10, 12, 25, 50, 100],
                        }}
                        muiTableBodyProps={{
                            sx: {
                                //stripe the rows, make odd rows a darker color
                                '& tr:nth-of-type(odd)': {
                                    backgroundColor: '#f5f5f5',
                                },
                            },
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                borderRight: '2px solid #e0e0e0', //add a border between columns
                            },
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            (!isLoading && !tableChange) ?
                                !isViewRole &&
                                <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            setOpenCreate(true)
                                            setSelectedRow(null)
                                        }}
                                    >
                                        <AddIcon />
                                        <FormattedMessage id="store.create" />
                                    </Button>
                                </Box>
                                :
                                <Box className='mt-3' sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                        )}
                        enableRowActions={!isViewRole}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '0rem' }}>
                                <Tooltip title="Edit">
                                    <IconButton disabled={tableChange} onClick={() => {
                                        setSelectedRow(row); // Store the selected row
                                        setOpenCreate(true); // Open the dialog
                                    }} color="secondary">
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton disabled={tableChange} color="error" onClick={() => handleDelete(row)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                </>
            }
        </div>
    )
}
