import React, { useContext } from 'react'
import { languageContext } from '../../context/languageContext';
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

export const SelectLanguage = ({ styles }) => {
    const language = useContext(languageContext);
    const location = useLocation();
    const isPostSurveyPage = location.pathname.includes("postsurvey");
    const availableLanguages = [
        {
            text: <FormattedMessage id="language.es.text" defaultMessage="Español" />,
            locale: "es",
            image: "/images/Languages/espana.png",
        },
        {
            text: <FormattedMessage id="language.en.text" defaultMessage="Ingles" />,
            locale: "en",
            image: "/images/Languages/reino-unido.png",
        },
        {
            text: <FormattedMessage id="language.fr.text" defaultMessage="Frances" />,
            locale: "fr",
            image: "/images/Languages/francia.png",
        }
    ]

    return (
        <div className={isPostSurveyPage ? "sl-nv-post RegularMadeTommy" : "sl-nav RegularMadeTommy"} style={styles}>
            <ul>
                <li>
                    <img src={availableLanguages.find(l => l.locale === language.locale).image} style={{ width: "16px" }} alt={availableLanguages.find(l => l.locale === language.locale).text} /> &nbsp;
                    <span>
                        {availableLanguages.find(l => l.locale === language.locale).text}
                    </span>
                    <div className="triangle"></div>
                    <ul>
                        {availableLanguages.map((l, i) => {
                            return (
                                <li key={i} className='RegularMadeTommy'>
                                    <img src={l.image} style={{ width: "16px" }} alt={l.text} />
                                    <span className={language.locale === l.locale ? "active" : ""} onClick={() => language.changeLanguage(l.locale)}>
                                        {l.text}
                                    </span>
                                </li>
                            )
                        })
                        }
                    </ul>
                </li>
            </ul>
        </div>
    )
}
