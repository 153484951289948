import React from 'react'
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const SendSurveyBtn = () => {
    const {
        answers,
        setAnswers,
        surveyStyles,
        handleNext,
        saveAnswersMutation,
        updateUserMutation,
        sending,
        setSending,
        surveyInfo,
        userId,
        customer,
        personalInfo,
        personalInfoErrors,
        setPersonalInfoErrors,
        personalInfoValidations,
        updateUserPersonalInfoMutation
    } = useSurveyContext();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        try {
            if ((surveyInfo?.personalInfo || surveyInfo?.requiredPersonalInfo)) {
                const personalResult = personalInfoValidations(personalInfo, personalInfoErrors, setPersonalInfoErrors, surveyInfo?.requiredPersonalInfo);
                if (personalResult) return;
                const formatedPersonalInfo = {
                    display_name: personalInfo.name,
                    email: personalInfo.correo,
                    phone: personalInfo.phone,
                    identification_number: personalInfo.Id
                }
                //check if formatedPersonalInfo has all null values
                if (!Object.values(formatedPersonalInfo).every(val => val === '')) {
                    await updateUserPersonalInfoMutation.mutateAsync(formatedPersonalInfo);
                }
            } else {
                //Esto es para que se valide la ultima pregunta
                const lastValidation = handleNext();
                if (lastValidation === null) {
                    return;
                }
            }
            const values = {
                answers,
                from: "survey" //Esto no me acuerdo para que es
            }

            const saveAnswers = await saveAnswersMutation.mutateAsync(values);
            if (saveAnswers === 200) {
                const updateUser = {
                    status: 4
                }
                await updateUserMutation.mutateAsync(updateUser);
                setAnswers([]);
                navigate(
                    `/postsurvey/${customer}/${userId}`,
                    {
                        state: {
                            surveyInfo,
                            surveyStyles
                        }
                    }
                );
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setSending(false);
        }
    }

    return (
        <button
            type="submit"
            onClick={handleSubmit}
            disabled={sending}
            style={{
                backgroundColor: surveyStyles.btnSubmit.bg,
                color: surveyStyles.btnSubmit.color
            }}
            className="btn btn-lg shadow mysurveybtn"
        >
            {sending ?
                <FormattedMessage
                    id="sending.button"
                />
                :
                <FormattedMessage
                    id="send.button"
                />}
        </button>
    )
}
