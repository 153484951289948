import { FilterDateRange } from '../ReuseComponents/FilterDateRange';
import { FormattedMessage } from 'react-intl';

export const DashboardTitle = () => {
    return (
        <div className='col-md-12 mt-3'>
            <div className='row inline'>
                <div className='col-sm-5'>
                    <h2 className='MediumMadeTommy' style={{ fontSize: "42px" }} color="text.secondary">
                        <FormattedMessage
                            id="dashboard.title"
                            defaultMessage="Dashboard"
                        /> &nbsp;
                    </h2>
                </div>
                <div className='col-md-7'>
                    <FilterDateRange />
                </div>
            </div>
        </div>
    )
}
