import { useTheme } from '@mui/material/styles';
import { Button, MobileStepper } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useSurveyContext } from '../Context/SurveyCustomHooks/useSurveyContext';
import { useSurveyStyles } from '../Context/SurveyCustomHooks/useSurveyStyles';
import { SelectLanguage } from '../../../ReuseComponents/SelectLanguage';
import { FormattedMessage } from 'react-intl';
// import Brightness4Icon from '@mui/icons-material/Brightness4';

export const SurveyHeader = () => {
    const theme = useTheme();
    const {
        activeStep,
        handleNext,
        handleBack,
        surveyBackgroundColor,
        questionsNumber,
        surveyStyles,
        isSmallScreen
    } = useSurveyContext();

    const btnBackColor = surveyStyles?.btnSubmit?.bg;

    const { makeColorDarker } = useSurveyStyles();
    return (
        <header>
            <div
                className="center"
                align="center"
                style={{
                    position: 'relative', // Necesario para posicionar el icono dentro del contenedor
                    width: '100vw', // Ocupa todo el ancho de la ventana
                    // paddingBottom: '10px',
                }}
            >
                <SelectLanguage styles={{ position: 'absolute', left: '30px', top: '10px', color: "black" }} />
                <a
                    target="_blank"
                    className="mylogo"
                    rel="noreferrer"
                    href={surveyStyles?.url}
                >
                    <img
                        className="mylogo"
                        alt="Logo"
                        src={surveyStyles?.logo}
                        border="0"
                    />
                </a>
                {/* <Brightness4Icon
                    onClick={() => setThemeMode(themeMode === 'dark' ? 'light' : 'dark')}
                    style={{
                        color: themeMode === 'dark' ? 'lightgray' : '#4a4949', // Color del icono
                        position: 'absolute', // Saca el icono del flujo normal
                        right: '10px', // Distancia del lado derecho
                        top: '18%', // Centrado vertical relativo al contenedor
                        transform: 'translateY(-50%)', // Asegura que esté perfectamente centrado verticalmente
                        cursor: 'pointer', // Cambia el cursor al pasar por encima
                    }}
                /> */}
            </div>
            {!isSmallScreen && <br />}
            <div className='text-center '>
                {surveyStyles?.subtitle && (
                    <h6 className="mt-1 LightMadeTommy" style={{ color: surveyStyles?.subtitleColor ?? "black" }}>
                        {surveyStyles.subtitle}
                    </h6>
                )}
            </div>
            <MobileStepper
                variant="progress"
                steps={questionsNumber}
                position="static"
                LinearProgressProps={{
                    sx: {
                        height: 6, // Increase the height
                        borderRadius: 5, // Optional: rounded corners
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: btnBackColor.trim().toLowerCase() === "#ffffff" ?
                                "black" :
                                btnBackColor, //color of the progress bar when it is loading
                        },
                        backgroundColor: btnBackColor.trim().toLowerCase() === "#ffffff" ?
                            "white" :
                            makeColorDarker(btnBackColor, 100), //color of the progress bar when it is not loading
                        width: isSmallScreen ? "60%" : "80%",
                    }
                }}
                activeStep={activeStep}
                sx={{
                    flexGrow: 1,
                    backgroundColor: surveyBackgroundColor,
                }}
                nextButton={
                    <Button
                        size="small"
                        style={activeStep === questionsNumber - 1 ?
                            { visibility: "hidden", } :
                            {
                                visibility: "hidden",
                                backgroundColor: surveyStyles?.btnSubmit.bg,
                                color: surveyStyles?.btnSubmit?.color
                            }
                        }
                        onClick={handleNext}
                        disabled={activeStep === questionsNumber - 1}
                    >
                        {!isSmallScreen && 'Next'}
                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        style={activeStep === 0 ?
                            {
                                border: `${!isSmallScreen ? '1.5px' : "0px"} solid lightgray`,
                                borderRadius: "10px",
                            } :
                            {
                                visibility: "visible",
                                backgroundColor: btnBackColor,
                                color: surveyStyles?.btnSubmit.color,
                                border: `${!isSmallScreen ? '1.5px' : "0px"} solid ${surveyStyles?.btnSubmit.bg}`,
                                borderRadius: "10px",
                                paddingRight: "10px",
                            }
                        }
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        {!isSmallScreen &&
                            <FormattedMessage
                                id="back"
                            />
                        }
                    </Button>
                }
            />
        </header>
    )
}
