import { FormattedMessage } from "react-intl";
import { CreateUser } from "../components/UserComponents/CreateUser";
import { CreateCustomer } from "../components/CustomerComponents/CreateCustomer";
import { Users } from "../components/UserComponents/Users";
import { CustomerStyles } from "../components/CustomerComponents/customerStyles";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StoreIcon from '@mui/icons-material/Store';
import StyleIcon from '@mui/icons-material/Style';


export const Routes = [
    {
        path: "/",
        name:
            <FormattedMessage
                id="navbar.home"
                defaultMessage="Inicio"
            />
    },
    {
        path: "/users",
        name:
            <>
                <SupervisedUserCircleIcon /> <FormattedMessage id="navbar.users" defaultMessage="Usuarios" />
            </>,
        protected: true,
        role: 1,
        component: <Users />
    },
    {
        path: "/createuser",
        name:
            <>
                <PersonAddIcon /> <FormattedMessage id="navbar.create.user" defaultMessage="Crear usuarios" />
            </>,
        protected: true,
        role: 1,
        component: <CreateUser />
    },
    {
        path: "/createcustomer",
        name: <><StoreIcon /> Crear cliente</>,
        protected: true,
        role: 1,
        component: <CreateCustomer />
    },
    {
        path: "/customerstyles",
        name: <><StyleIcon /> Estilos de cliente</>,
        protected: true,
        role: 1,
        component: <CustomerStyles />
    }
];