import { FormattedMessage, useIntl } from "react-intl"
import useLocalStorage from "../../../../CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { MainFooter } from "../../../ReuseComponents/MainFooter";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../../../../CustomHooks/useAxiosPrivate";
import StarIcon from '@mui/icons-material/Star';

export const RestaurantPostSurvey = ({ url, logo, headerBackground, bodyBackground, fontColor, category, survey }) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const intl = useIntl();
    const { userId, customer } = useParams();
    const [clickGoogleReview, setClickGoogleReview] = useLocalStorage(`ClickGR${userId}`, 0);
    const [placeId, setPlaceId] = useState();
    const [storeName, setStoreName] = useState("");

    document.body.style.backgroundColor = headerBackground;
    const fontpostcolor = "#6a6a6a"

    const { data: store, isLoading: IsLoadingStore } = useQuery({
        queryKey: ["postSurveyStore", customer, survey.survey_id, survey.store],
        queryFn: async () => {
            try {
                const response = await axiosPrivate.get(`store/${survey.store}?customer=${customer}&survey=${survey.survey_id}`);
                return response.data[0]
            } catch (err) {
                // console.error(err);
                return null;
            }
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });

    useEffect(() => {
        if (store) {
            setStoreName(store.name);
            setPlaceId(store.place_id);
        }
    }, [store]);

    const handleCreateSurvey = async () => {
        const confirmBtn = window.confirm(intl.formatMessage({ id: "create.new.survey.msg" }));
        if (confirmBtn) {
            navigate(`/newsurvey/${customer}/${survey.store ? survey.store : survey.name}`, {});
        }
    }

    const forReview = (e) => {
        setClickGoogleReview(1);
        window.open(placeId?.includes("tripadvisor") ?
            placeId :
            `https://search.google.com/local/writereview?placeid=${placeId}`, "_blank")
    }


    // if (IsLoadingStore) {
    //     return (
    //         <LoadingComponent errorMsg={null} />
    //     )
    // }

    return (
        <>
            <section id="hirev2" style={{ background: bodyBackground, padding: "2%" }}>
                <div className="container" align="center" style={{ background: headerBackground, borderRadius: "20px", padding: "50px 0% 50px 0%" }}>
                    <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href={url}>
                        <img className="img1" alt="Logo" src={logo} border="0" />
                    </a>
                </div>
                <div className="container" style={{ borderRadius: "20px", padding: "10rem 0rem", minHeight: "480px" }}>
                    <div>
                        <div className="tdBody" style={{ padding: "0" }} align="center"  >
                            <div className="" border="0" cellPadding="0" cellSpacing="0" >
                                <div>
                                    <>
                                        <div>
                                            <div className="tdBody3" align="center"  ><br></br>
                                                {
                                                    IsLoadingStore ?
                                                        <h1 className="masthead-subheading RegularMadeTommy" style={{ color: fontpostcolor }}>
                                                            <FormattedMessage
                                                                id="loading.msg"
                                                            />
                                                        </h1>
                                                        :
                                                        placeId ?
                                                            clickGoogleReview === 0 ?
                                                                <div className="RegularMadeTommy" id="review" >
                                                                    <h3 style={{ color: fontColor }}>
                                                                        <FormattedMessage
                                                                            id="rating.msg"
                                                                            values={
                                                                                {
                                                                                    restaurantName: storeName
                                                                                }
                                                                            }
                                                                            defaultMessage="¿Qué puntuación le darías a ?"
                                                                        />
                                                                    </h3>
                                                                    <div>
                                                                        <div className="star-wrapper" style={{ color: "white" }}>
                                                                            <StarIcon className="s1 star" onClick={forReview} />
                                                                            {[...Array(4)].map((_, i) => (
                                                                                <StarIcon key={i} className={`s${i + 2} star`} onClick={() => setClickGoogleReview(i + 2)} />
                                                                            ))}
                                                                        </div>
                                                                        <p className="mt-3" style={{ color: fontColor }}>
                                                                            <FormattedMessage
                                                                                id="rating.msg.description"
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                survey.name ?
                                                                    <h1 className="masthead-subheading RegularMadeTommy" style={{ color: fontpostcolor }}>
                                                                        <FormattedMessage
                                                                            id="complete.survey.msg.name"
                                                                            values={
                                                                                {
                                                                                    name: survey.name
                                                                                }
                                                                            }
                                                                        />
                                                                    </h1>
                                                                    :
                                                                    <h1 className="masthead-subheading RegularMadeTommy" style={{ color: fontpostcolor }}>
                                                                        <FormattedMessage
                                                                            id="complete.survey.msg"
                                                                        />
                                                                    </h1>
                                                            :
                                                            survey.name ?
                                                                <h1 className="masthead-subheading mt-5 RegularMadeTommy" style={{ color: fontpostcolor }}>
                                                                    <FormattedMessage
                                                                        id="complete.survey.msg.name"
                                                                        values={
                                                                            {
                                                                                name: survey.name
                                                                            }
                                                                        }
                                                                    />
                                                                </h1>
                                                                :
                                                                <h1 className="masthead-subheading mt-5 RegularMadeTommy" style={{ color: fontpostcolor }}>
                                                                    <FormattedMessage
                                                                        id="complete.survey.msg"
                                                                    />
                                                                </h1>
                                                }
                                                {category?.includes("hotel") &&
                                                    <>
                                                        <br />
                                                        <button onClick={handleCreateSurvey} style={{ color: "black" }} className="btn btn-info">
                                                            <FormattedMessage
                                                                id="create.new.survey"
                                                            />
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                </div>
                                {/* {customerStyles[Object.keys(customerStyles)].plan.includes("piloto") &&
                            <AdComponent dataAdSlot="9228488024" path={window.location.href} />
                        } */}
                            </div>
                        </div>
                    </div>
                </div >
            </section>
            <MainFooter className='select-dropdown' backColor={headerBackground} />
        </>
    )
}
