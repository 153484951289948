import { Autocomplete, Box, Button, debounce, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'autosuggest-highlight/parse';
import { MainDialogTitle } from '../ReuseComponents/MainDialogTitle';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from '../../CustomHooks/useAxiosPrivate';
import useAuth from '../../CustomHooks/useAuth';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const autocompleteService = { current: null };

function loadScript(src, position, id) {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}
export const CreateStoreDialog = ({ setOpenCreate, openCreate, row, setTableChange }) => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { defaultCustomer } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [creating, setCreating] = useState(false);
    //#region For Location
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const initialPlaceInfo = {
        name: '',
        address: '',
        city: '',
        country: '',
        placeId: ''
    }
    const [placeInfo, setPlaceInfo] = useState(initialPlaceInfo);

    useEffect(() => {
        if (row?.original) {
            setPlaceInfo(p => ({
                ...p,
                name: row.original.name ?? '',
                address: row.original.address ?? '',
                city: row.original.city ?? '',
                country: row.original.country_code ?? '',
                placeId: row.original.place_id ?? '',
                id: row.original.id
            }));
        } else {
            setPlaceInfo(initialPlaceInfo);
        }
    }, [row]);

    useEffect(() => {
        if (value) {
            const address = value.structured_formatting.secondary_text;
            const addressArray = address.split(', ');
            setPlaceInfo(p => ({
                ...p,
                name: value.structured_formatting.main_text,
                placeId: value.place_id,
                address,
                city: addressArray[addressArray.length - 3]?.toUpperCase(),
                country: addressArray[addressArray.length - 1]?.slice(0, 2)?.toUpperCase()
            }));
        } else {
            setPlaceInfo(initialPlaceInfo);
        }
    }, [value]);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`,
                document.querySelector('head'),
                'google-maps',
            );
        }
        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);
    //#endregion

    const createStoreMutation = useMutation({
        mutationFn: async (values) => {
            setCreating(true);
            setTableChange(true);
            const response = await axiosPrivate.post(
                `/store?customer=${defaultCustomer}`,
                values
            )
            return response.data;
        },
        onSuccess: (data, values) => {
            alert(intl.formatMessage({ id: "store.create.success" }));
            setOpenCreate(false);
            queryClient.invalidateQueries(["MyStores", defaultCustomer], { exact: true });
            setPlaceInfo(initialPlaceInfo);
            setValue(null);
            setOptions([]);
        },
        onError: (error) => {
            console.error(error);
            alert(intl.formatMessage({ id: "store.create.error" }));
        },
        onSettled: () => {
            setCreating(false);
            setTableChange(false);
        }
    });

    const updateStoreMutation = useMutation({
        mutationFn: async (values) => {
            setCreating(true);
            setTableChange(true);
            const response = await axiosPrivate.put(
                `/store?customer=${defaultCustomer}`,
                values
            )
            return response.data;
        },
        onSuccess: (data, values) => {
            alert(intl.formatMessage({ id: "store.edit.success" }));
            setOpenCreate(false);
            queryClient.invalidateQueries(["MyStores", defaultCustomer], { exact: true });
            setOptions([]);
            setValue(null);
        },
        onError: (error) => {
            console.error(error);
            alert(intl.formatMessage({ id: "store.edit.error" }));
        },
        onSettled: () => {
            setCreating(false);
            setTableChange(false);
        }
    });

    const infoChanged = (e) => {
        setPlaceInfo(p => ({
            ...p,
            [e.target.id]: e.target.value
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (placeInfo.placeId === '') {
            alert(intl.formatMessage({ id: "store.place.id.error.empty" }));
            return;
        }
        //Add storeCode
        placeInfo.storeCode = placeInfo.name.slice(0, 4)?.toUpperCase() + placeInfo.city.slice(0, 3)?.toUpperCase() + placeInfo.placeId?.slice(-5);
        if (row) {
            updateStoreMutation.mutate(placeInfo)
        } else {
            createStoreMutation.mutate(placeInfo);
        }
    }
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={openCreate}
            onClose={() => setOpenCreate(false)}
        >
            <MainDialogTitle id="transition-modal-title" onClose={() => setOpenCreate(false)}>
                {row ?
                    <Box sx={{ color: 'secondary.main' }} >
                        <EditIcon /> &nbsp;
                        <FormattedMessage id="store.edit" />
                    </Box>
                    :
                    <Box sx={{ color: 'primary.main' }} >
                        <AddIcon /> &nbsp;
                        <FormattedMessage id="store.create" />
                    </Box>
                }
            </MainDialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: "justify" }}>
                    {row ?
                        <FormattedMessage id="store.edit.description" />
                        :
                        <FormattedMessage id="store.create.description" />
                    }
                </DialogContentText>
                <Box
                    className='mt-3'
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: '100%',
                    }}
                >
                    <Autocomplete
                        id="google-map-demo"
                        fullWidth
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.description
                        }
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        noOptionsText={<FormattedMessage id="register.no.location" />}
                        onChange={(event, newValue) => {
                            setOptions(newValue ? [newValue, ...options] : options);
                            setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={<FormattedMessage id="register.add.location" />}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    borderRadius: "10px",
                                    backgroundColor: "white"
                                }}
                            />
                        )}
                        renderOption={(props, option) => {
                            const matches =
                                option.structured_formatting.main_text_matched_substrings || [];
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                            );
                            return (
                                <li {...props}>
                                    <Grid container alignItems="center">
                                        <Grid item sx={{ display: 'flex', width: 44 }}>
                                            <LocationOnRoundedIcon sx={{ color: 'text.secondary' }} />
                                        </Grid>
                                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                            {parts.map((part, index) => (
                                                <Box
                                                    key={index}
                                                    component="span"
                                                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                                >
                                                    {part.text}
                                                </Box>
                                            ))}
                                            <Typography variant="body2" color="text.secondary">
                                                {option.structured_formatting.secondary_text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                    />
                    <TextField
                        className='mt-3'
                        // error
                        id="name"
                        label={<FormattedMessage id="store.table.name" />}
                        value={placeInfo.name}
                        onChange={infoChanged}
                    />
                    <TextField
                        className='mt-3'
                        id="address"
                        label={<FormattedMessage id="store.table.address" />}
                        value={placeInfo.address}
                        onChange={infoChanged}
                    />
                    <TextField
                        className='mt-3'
                        // error
                        id="city"
                        label={<FormattedMessage id="store.table.city" />}
                        value={placeInfo.city}
                        onChange={infoChanged}
                    />
                    <Tooltip title={<FormattedMessage id="store.place.id.helper" />} placement="top">
                        <TextField
                            className='mt-3'
                            // error
                            id="placeId"
                            label="Google maps Id *"
                            value={placeInfo.placeId}
                            onChange={infoChanged}
                            placeholder={intl.formatMessage({ id: "store.place.id.example" })}
                            helperText={
                                <>
                                    <FormattedMessage id="store.place.id.description" /> &nbsp;
                                    <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "blue", textDecoration: "underline" }}
                                    >
                                        Aquí
                                    </a>
                                    &nbsp;
                                    <FormattedMessage id="store.place.id.description.2" />
                                </>
                            }
                        />
                    </Tooltip>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenCreate(false)} color="error">
                    <FormattedMessage id="cancel" />
                </Button>
                <Button onClick={handleSubmit} color={row ? "secondary" : "primary"} disabled={creating}>
                    {
                        creating ?
                            row ? <FormattedMessage id="updating" /> :
                                <FormattedMessage id="creating.user.btn" />
                            :
                            row ? <FormattedMessage id="update" />:
                                <FormattedMessage id="create.user.btn" />
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}
