import { CardElementComponent } from '../UtilsComponents/CardElementComponent'
import { FormattedMessage, useIntl } from 'react-intl'
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { useResizeChart } from "./ResizeCharts";
import { useEffect, useState } from 'react';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {new Date(payload.value).toLocaleString('default', { day: 'numeric', month: 'short', timeZone: 'UTC' })}
            </text>
        </g>
    );
}

export const SentSurveysStackedBarChart = ({ chartName, data, isLoading, isRefetching, isError, error }) => {
    const intl = useIntl();
    const [selectInfo, setSelectInfo] = useState("completed");

    const Width = useResizeChart(chartName);
    const [chartWidth, setChartWidth] = useState(Width);

    const getWidth = () => {
        const cardWith = document.getElementById(chartName);
        if (cardWith) {
            return cardWith.clientWidth;
        }
        return 0;
    }

    useEffect(() => {
        setChartWidth(Width);
    }, [Width]);

    useEffect(() => {
        const resultWidth = getWidth();
        setChartWidth(resultWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isRefetching]);

    const rederFormatedTooltip = (value, name, props) => {
        if (selectInfo === "completed") {
            return [value, name === 'completed' ? intl.formatMessage({ id: "dashboard.completed" }) : intl.formatMessage({ id: "dashboard.opened" })]
        } else {
            return [value, name === 'sent' ? intl.formatMessage({ id: "dashboard.sent" }) : intl.formatMessage({ id: "dashboard.sending.error" })]
        }
    }
    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        if (selectInfo === "completed") {
            return <span className='RegularMadeTommy' style={{ color }}>{value === 'completed' ? intl.formatMessage({ id: "dashboard.completed" }) : intl.formatMessage({ id: "dashboard.opened" })}</span>;
        } else {
            return <span className='RegularMadeTommy' style={{ color }}>{value === 'sent' ? intl.formatMessage({ id: "dashboard.sent" }) : intl.formatMessage({ id: "dashboard.sending.error" })}</span>;
        }
    };

    return (
        <CardElementComponent sx={{ height: "346px" }} >
            <div className="d-flex align-items-center justify-content-between my-2 px-3">
                {/* Título */}
                <h2 className="MediumMadeTommy m-0" style={{ paddingLeft: "10px", color: "#2b364a" }}>
                    <FormattedMessage id="dashboard.survey.by.date" />
                </h2>

                {/* Select a la derecha */}
                <FormControl
                    className="RegularMadeTommy"
                    style={{ minWidth: "100px", paddingRight: "18px" }}
                    size="small"
                >
                    <InputLabel id="SurveyNumberSelect">
                        <RemoveRedEyeTwoToneIcon /> Mostrar
                    </InputLabel>
                    <Select
                        labelId="SurveyNumberSelect"
                        id="Mostrar"
                        value={selectInfo}
                        label="sho Mostrar"
                        onChange={(e) => setSelectInfo(e.target.value)}
                    >
                        <MenuItem key="completed" value="completed">
                            <FormattedMessage id="dashboard.completed" />
                        </MenuItem>
                        <MenuItem key="sent" value="sent">
                            <FormattedMessage id="dashboard.sent" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>

            {
                (isLoading) ?
                    <Skeleton animation="wave" variant="rounded" height={270} width={chartWidth - 70} />
                    :
                    isError ?
                        <h4 className='text-center RegularMadeTommy' style={{ color: error?.response?.status === 404 ? "orange" : "red" }}>
                            {error?.response?.status === 404 ?
                                <FormattedMessage id="dashboard.no.surveys.data.error.btn" />
                                :
                                <FormattedMessage id="dashboard.survey.info.error" />
                            }
                        </h4>
                        :
                        <BarChart
                            width={chartWidth}
                            height={300}
                            data={data}
                            margin={{
                                top: 0,
                                right: 30,
                                left: 10,
                                bottom: 55,
                            }}
                        >
                            {/* <CartesianGrid strokeDasharray="3 3" vertical={false} /> */}
                            <XAxis dataKey="date" className='RegularMadeTommy' tick={<CustomizedAxisTick />} />
                            <YAxis className='RegularMadeTommy' />
                            <Tooltip formatter={rederFormatedTooltip} />
                            <Legend verticalAlign="top" align="right" iconType='circle' formatter={renderColorfulLegendText} />
                            {
                                selectInfo === "completed" ?
                                    <>
                                        <Bar dataKey="opened" stackId="a" fill="#bdbdbd" radius={[0, 0, 0, 0]} />
                                        <Bar dataKey="completed" stackId="a" fill="#a197f8" radius={[7, 7, 0, 0]} />
                                    </>
                                    :
                                    <>
                                        <Bar dataKey="sent" stackId="a" fill="#7f77c3" radius={[0, 0, 0, 0]} />
                                        <Bar dataKey="errors" stackId="a" fill="#e45a56" radius={[7, 7, 0, 0]} />
                                    </>
                            }
                        </BarChart>
            }
        </CardElementComponent>
    )
}
