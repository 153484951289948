import React from 'react'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSurveyStyles } from '../SurveyComponents/NewSurveyFront/Context/SurveyCustomHooks/useSurveyStyles';
import { SurveySkeleton } from '../SurveyComponents/NewSurveyFront/SurveyComponents/SurveySkeleton';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../src/api/axios';
import { QuestionError } from '../SurveyComponents/NewSurveyFront/SurveyComponents/SurveyQuestions/QuestionError';
import { NotFound } from '../ErrorComponents/NotFound';
import MetaDecorator from '../UtilsComponents/MetaDecorator';

export const SurveyStore = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    let { surveyId, customer } = useParams();
    customer = customer.toLowerCase();
    const isSmallScreen = useMediaQuery('(max-width:480px)');
    const { surveyStyles, isLoadingStyles, isErrorStyles } = useSurveyStyles();
    const [selectedStore, setSelectedStore] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState('');
    const { data: stores, isLoading: isLoadingStores, isError: isErrorStores } = useQuery({
        queryKey: ["Stores", surveyId],
        queryFn: async () => {
            const response = await axios.get(`store/survey/${surveyId}?customer=${customer}`);
            const result = response.data
            // Si solo hay una tienda, redirige a la encuesta enseguida
            if (result.length === 1) {
                navigate(`/newsurvey/${customer}/${result[0].store_code}?survey=${surveyId}`);
            }
            return result;
        },
        refetchOnWindowFocus: false,
        retry: 0,
    });
    // const surveyStyles = template["7bytes"]
    const backgroundColor = 'whitesmoke'
    const questionsByTypes = {
        "uso personal": <FormattedMessage id="store.where.did.you.buy" />,
        "tiendas fisicas": <FormattedMessage id="store.where.did.you.buy" />,
        "any": <FormattedMessage id="store.where.are.you" />
    }
    const questionPlaceHolder = customer === 'andidelfuturo' ? "Selecciona una empresa" : <FormattedMessage id="store.select" />

    const questionLabel = customer === 'andidelfuturo' ? "¿A qué empresa perteneces?" : questionsByTypes[surveyStyles?.type] ?? questionsByTypes["any"]

    const handleStoreChange = (e, value) => {
        setSelectedStore(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!selectedStore) {
            setErrorMsg(intl.formatMessage({ id: "store.select.error" }))
            return
        }
        setErrorMsg('')
        navigate(`/newsurvey/${customer}/${selectedStore.store_code}?survey=${surveyId}`);
    }

    return (
        isLoadingStyles || isLoadingStores ? <SurveySkeleton /> :
            isErrorStores || isErrorStyles ? <NotFound /> :
                <Box
                    sx={{
                        backgroundColor,
                        height: "100vh",
                        overflow: "hidden", // Scroll personalizado
                    }}
                >
                    <MetaDecorator
                        title={`Store | ${customer}`}
                        description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                        imageUrl={"/images/7Bytes.svg"}
                        imageAlt={"7Bytes"}
                        path={window.location.pathname}
                    />
                    <header>
                        <div
                            className="center"
                            align="center"
                            style={{
                                position: 'relative', // Necesario para posicionar el icono dentro del contenedor
                                width: '100vw', // Ocupa todo el ancho de la ventana
                                paddingBottom: '40px',
                            }}
                        >
                            <a
                                target="_blank"
                                className="mylogo"
                                rel="noreferrer"
                                href={surveyStyles?.url}
                            >
                                <img
                                    className="mylogo"
                                    alt="Logo"
                                    src={surveyStyles?.logo}
                                    border="0"
                                />
                            </a>
                        </div>
                        <div className='text-center'>
                            {surveyStyles?.subtitle && (
                                <h6 className="mt-1 LightMadeTommy" style={{ color: surveyStyles?.subtitleColor ?? "black" }}>
                                    {surveyStyles.subtitle}
                                </h6>
                            )}
                        </div>
                    </header>
                    <div className={`container text-center`} style={{ marginTop: "6vh", }}>
                        <h3 className='MediumMadeTommy mb-3' >
                            {questionLabel}
                        </h3>
                        <Autocomplete
                            className='center RegularMadeTommy '
                            disablePortal
                            id={`selectradiostore`}
                            options={stores}
                            sx={{ width: isSmallScreen ? '100%' : '70%', backgroundColor: 'white', marginTop: "6vh", }}
                            getOptionLabel={(option) => option.name}
                            onChange={handleStoreChange}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li
                                        {...props}
                                        className="RegularMadeTommy"
                                        key={option.id}
                                        style={{
                                            textAlign: "left",
                                            padding: isSmallScreen ? "8px 30px" : "5px 30px",//Separacion de las opciones
                                            position: "relative",
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            style={{
                                                margin: "0px ",
                                                position: "absolute",
                                                left: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                            }}
                                            checked={selected}
                                            readOnly
                                        />
                                        {option.name}
                                    </li>
                                )
                            }}
                            renderInput={(params) =>
                                <TextField
                                    key={`textfieldstore`}
                                    {...params}
                                    // onFocus={() => setInputClick(true)}
                                    // onBlur={() => setInputClick(false)}
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: 'none', // Elimina el borde del campo
                                            }
                                        },
                                    }}
                                    label={questionPlaceHolder}
                                    className="MediumMadeTommy"
                                />
                            }
                        />
                        <QuestionError
                            errorMsg={errorMsg}
                            showError={errorMsg !== ''}
                        />
                    </div>
                    <div className={`divmysurveybtn `}>
                        <button
                            type="submit"
                            style={{
                                backgroundColor: surveyStyles.btnSubmit.bg,
                                color: surveyStyles.btnSubmit.color
                            }}
                            className="btn btn-lg shadow mysurveybtn"
                            onClick={handleSubmit}
                        >
                            <FormattedMessage
                                id="next"
                            />
                        </button>
                    </div>
                </Box>
    )
}
