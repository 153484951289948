import React from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { LinearProgress, Tooltip } from '@mui/material';

export const SurveyCards = ({ image, name, styles, icon, click, checkClickState }) => {
    //check if any survey is clicked
    const allClicks = Object.values(checkClickState).some((val) => val === true);
    const checkClick = checkClickState[name];
    //This is to disable all other cards when one is clicked
    const validate = allClicks && !checkClick;
    return (
        <Tooltip title={checkClick || validate ? "" : name}>
            <Card
                onClick={checkClick || validate ? undefined : click}
                className="mx-1"
                sx={{
                    opacity: validate ? 0.6 : 1,
                    maxWidth: 300,
                    minWidth: 260,
                    minHeight: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "12px",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    backgroundColor: "white",
                    cursor: checkClick || validate ? "default" : "pointer",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    {icon}
                    <Typography className='RegularMadeTommy' variant="h6" sx={{ color: "#333333", fontWeight: 500 }}>
                        {name}
                    </Typography>
                </div>
                <LinearProgress color="info" sx={{ width: "100%", display: !checkClick ? "none" : "block" }} />
            </Card>
        </Tooltip>

    )
}
