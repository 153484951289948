import { MainFooter } from '../../../ReuseComponents/MainFooter'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";
import { PostSurveyImage } from './PostSurveyImage';
import MetaDecorator from '../../../UtilsComponents/MetaDecorator';

export const SevenBytesPostSurvey = ({ survey, fontPostSurveyColor = "#2b364a", backColor = "#051B29" }) => {
    let { customer } = useParams();
    customer = customer.toLowerCase();
    const createNewSurveyIds = ["34207ecf-5af4-4b24-b21f-3077d7bd588f", "8d1b231d-6b46-4af2-adaf-63ef56856761"];
    const checkPhoneWidth = useMediaQuery('(max-width:760px)');

    var videoSrc = "";
    var videoTitle = "";

    if (customer === "hermeco") {
        videoSrc = "https://www.youtube.com/embed/PjEXuPbTBsk";
        videoTitle = "Caso de éxito: OFFCORSS | 7Bytes";
    } else {
        videoSrc = "https://www.youtube.com/embed/a65sY5VCT2s";
        videoTitle = "Conoce a 7Bytes: Estudio de mercado continuo";
    }

    return (
        <>
            <section id="hirev2" style={{ paddingTop: "2%" }}>
                <MetaDecorator
                    key={0}
                    title={customer?.includes("20Mission".toLowerCase()) ? `Survey | 20Mission Cerveza` : `Survey | ${customer.split(" ").map(subStr => subStr[0].toUpperCase() + subStr.slice(1)).join(" ")}`}
                    description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                    imageUrl={"/images/7Bytes.svg"}
                    imageAlt={"7Bytes"}
                    path={window.location.pathname}
                />
                {(typeof survey.imglink === 'string') && checkPhoneWidth && <PostSurveyImage openImageLink={survey?.openImageLink} imglink={survey?.imglink} />}
                {(!checkPhoneWidth || !(typeof survey.imglink === 'string')) &&
                    <div className="container" align="center">
                        <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href="https://7bytesms.com/">
                            <img className="img1" alt="Logo" src="/images/7Bytes.svg" border="0" />
                        </a>
                    </div>
                }
                <div className="container" style={{ borderRadius: "20px", minHeight: "480px" }}>
                    <div>
                        <div className="tdBody RegularMadeTommy" style={{ padding: "0" }} align="center"  >
                            <div className="" border="0" cellPadding="0" cellSpacing="0" >
                                <div>
                                    {survey.name ?
                                        <h1 className="masthead-subheading mt-5 postmsg RegularMadeTommy" style={{ color: fontPostSurveyColor }}>
                                            <FormattedMessage
                                                id="complete.survey.msg.name"
                                                values={
                                                    {
                                                        name: survey.name
                                                    }
                                                }
                                            />
                                        </h1>
                                        :
                                        <h1 className="masthead-subheading mt-5 postmsg RegularMadeTommy" style={{ color: fontPostSurveyColor }}>
                                            <FormattedMessage
                                                id="complete.survey.msg"
                                            />
                                        </h1>
                                    }
                                    {
                                        createNewSurveyIds.includes(survey?.survey_id) &&
                                        <>
                                            <br />
                                            <Link
                                                to={`/${customer === "hermeco" ? "user" : "newsurvey"}/${customer}/${survey?.survey_source}`}
                                                style={
                                                    {
                                                        color: "whitesmoke",
                                                        backgroundColor: "#1d999e",
                                                    }
                                                }
                                                className="btn btn-info"
                                            >
                                                <FormattedMessage
                                                    id="create.new.survey"
                                                />
                                            </Link>
                                            <br />
                                        </>
                                    }
                                    <br></br>
                                    {(typeof survey.imglink === 'string') ?
                                        <div className="wrapper">
                                            {!checkPhoneWidth && <PostSurveyImage openImageLink={survey.openImageLink} imglink={survey.imglink} />}
                                            {/* <div>
                                                            <a href={survey.openImageLink} target='_blank' rel='noreferrer'>
                                                                <img className="img-container-postSurvey" src={survey.imglink} alt='PostsurveyImage' />
                                                            </a>
                                                        </div> */}
                                            {/* SVG Img */}
                                            {!checkPhoneWidth ?
                                                <div className="svg-image RegularMadeTommy" >
                                                    <div className="button-container btn-primary text-uppercase">
                                                        <a href="https://7bytesms.com/contacto/" target="_blank" rel="noopener noreferrer">
                                                            <button>Descubre cómo</button>
                                                        </a>
                                                    </div>
                                                </div> :
                                                <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href="https://7bytesms.com/">
                                                    <img className="img1" style={{ width: "130px" }} alt="Logo" src="/images/7Bytes.svg" border="0" />
                                                </a>
                                            }
                                        </div>
                                        :
                                        <div className="wrapper">
                                            {/* Youtube Video */}
                                            <div className="team-member" style={{ position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', padding: "0px 2% 0px 2%" }} >
                                                <iframe style={{ borderRadius: "5px" }} width="100%" height="400" src={videoSrc} title={videoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            {/* SVG Img */}
                                            <div className="svg-image" >
                                                <div className="button-container btn-primary text-uppercase">
                                                    <a href="https://7bytesms.com/contacto/" target="_blank" rel="noopener noreferrer">
                                                        <button>Descubre cómo</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* <AdComponent dataAdSlot="9228488024" path={window.location.href} /> */}
                            </div>
                        </div>
                    </div>
                </div >
            </section>
            <MainFooter backColor={backColor} />
        </>
    )
}
