import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useDashboardContext } from '../../CustomHooks/useDashboardContext';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatesModal } from '../DashboardComponents.js/DatesModal';
import { FormattedMessage } from 'react-intl';

export const FilterDateRange = () => {
    const { rangeDate, setRangeData, availableRanges, initDate, endDate } = useDashboardContext();
    const [open, setOpen] = React.useState(false);
    
    const convertDate = (dateStr) => {
        let inputDate = new Date(dateStr);
        inputDate = new Date(inputDate.getTime() + 5 * 60 * 60 * 1000); // Adding 5 hours
        let formattedDate = new Intl.DateTimeFormat('es-ES', {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        }).formatToParts(inputDate).map(part => {
            if (part.type === 'month') {
                return part.value.charAt(0).toUpperCase() + part.value.slice(1);
            }
            return part.value;
        }).join('');
        //replace the las space with a comma
        formattedDate = formattedDate.replace(/\s(?=\S*$)/, ', ');
        return formattedDate;
    };
    const [initDateState, setInitDateState] = React.useState(convertDate(initDate));
    const [endDateState, setEndDateState] = React.useState(convertDate(endDate));

    useEffect(() => {
        setInitDateState(convertDate(initDate));
    }, [initDate]);
    useEffect(() => {
        setEndDateState(convertDate(endDate));
    }, [endDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setRangeData(event.target.value);
    };

    const handleClick = () => {
        setOpen(true);
    };

    return (
        <div className="filter-date-range">
            <div className="date-range-display">
                <CalendarMonthIcon />
                <span>{initDateState} - {endDateState}</span>
            </div>
            <div className="divider" />
            <FormControl size="small" className="date-range-select">
                <Select
                    value={rangeDate}
                    onChange={handleChange}
                    displayEmpty
                    sx={{ minWidth: 120, border: 'none', boxShadow: 'none' }}
                >
                    {availableRanges.map((range, index) => (
                        <MenuItem key={index} value={range}>
                            <FormattedMessage id="dashboard.date.range.options" values={{ range }} />
                        </MenuItem>
                    ))}
                    <MenuItem onClick={handleClick} value={0}>
                        <FormattedMessage id="dashboard.date.range.custom" />
                    </MenuItem>
                </Select>
            </FormControl>
            <DatesModal open={open} handleClose={handleClose} />
        </div>
    );
};
