import { useMutation } from "@tanstack/react-query";
import axios from "../../../../../api/axios";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const usePersonalInfo = () => {
    let { userId, customer } = useParams();

    /**
     * Validates the personal information fields and sets the corresponding error messages.
     *
     * @param {Object} personalInfo - The personal information object containing the fields to be validated.
     * @param {string} personalInfo.name - The name of the person.
     * @param {string} personalInfo.Id - The identification number of the person.
     * @param {string} personalInfo.correo - The email address of the person.
     * @param {string} personalInfo.phone - The phone number of the person.
     * @param {Object} personalInfoErrors - The object containing the current error messages for the personal information fields.
     * @param {Function} setPersonalInfoErrors - The function to update the error messages for the personal information fields.
     * @returns {boolean} - Returns true if there are any validation errors, otherwise false.
     */
    const personalInfoValidations = (personalInfo, personalInfoErrors, setPersonalInfoErrors, requiredPersonalInfo) => {
        let errors = { ...personalInfoErrors };
        let hasError = false;
        if (personalInfo.name === '' && requiredPersonalInfo) {
            errors.name = <FormattedMessage id="amonymous.survey.name.required"/>
            hasError = true;
        } else {
            errors.name = '';
        }
        if (personalInfo.Id === '' && requiredPersonalInfo) {
            errors.Id = <FormattedMessage id="amonymous.survey.id.required"/>
            hasError = true;
        } else if (requiredPersonalInfo && (personalInfo.Id < 1111111 || personalInfo.Id > 9999999999)) {
            errors.Id = <FormattedMessage id="amonymous.survey.id.error"/>
            hasError = true;
        }
        else {
            errors.Id = '';
        }
        if (personalInfo.correo === '' && requiredPersonalInfo) {
            errors.correo = <FormattedMessage id="amonymous.survey.email.required"/>
            hasError = true;
        } else if (requiredPersonalInfo && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalInfo.correo)) {
            errors.correo = <FormattedMessage id="amonymous.survey.email.error"/>
            hasError = true;
        }
        else {
            errors.correo = '';
        }
        if (personalInfo.phone === '' && requiredPersonalInfo) {
            errors.phone = <FormattedMessage id="amonymous.survey.phone.required"/>
            hasError = true;
        } else if (requiredPersonalInfo && personalInfo.phone.length !== 10) {
            errors.phone = <FormattedMessage id="amonymous.survey.phone.error"/>
            hasError = true;
        }
        else {
            errors.phone = '';
        }
        setPersonalInfoErrors(errors);
        return hasError;
    };

    const updateUserPersonalInfoMutation = useMutation({
        mutationFn: async (values) => {
            const response = await axios.put(`users/${userId}?customer=${customer}`, values);
            return response.status;
        },
        onError: (error) => {
            if (error.response.status === 400) {
                console.error(error);
            } else if (error.response.status === 409) {
                // console.error(error.response?.data?.text);
                console.error(error);
            } else {
                console.error(error);
            }
        }
    });

    return {
        personalInfoValidations,
        updateUserPersonalInfoMutation
    }
}
