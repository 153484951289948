import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Cell, Legend, Pie, PieChart, } from 'recharts';
import { CardElementComponent } from '../UtilsComponents/CardElementComponent';
import { Skeleton } from '@mui/material';

const RADIAN = Math.PI / 180;
const data = [
    { name: <FormattedMessage id="dashboard.nps.detractors" />, value: 100, color: '#e57c62' },
    { name: <FormattedMessage id="dashboard.nps.passives" />, value: 50, color: '#fbd149' },
    { name: <FormattedMessage id="dashboard.nps.promoters" />, value: 50, color: '#6cf3d5' },
];
const cx = 150;
const cy = 120;
const iR = 50;
const oR = 100;

const reEstructuredWhenLegendOnTop = 24;

const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    value = value + 100;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5 + reEstructuredWhenLegendOnTop;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" key={ang} />,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} key={ang + "path"} stroke="#none" fill={color} />,
    ];
};

export const NPS = ({ nps, isLoading, isError }) => {

    return (
        <CardElementComponent sx={{ height: "246px" }} >
            <h2 className='text-center MediumMadeTommy' style={{ color: "#2b364a" }}>
                NPS
            </h2>
            {
                (isLoading) ?
                    <Skeleton animation="wave" variant="rounded" height={186} width={300} />
                    :
                    isError ?
                        <>
                            <h2 className='text-center' style={{ color: "red" }}>
                                Error
                            </h2>
                            <h5 className='text-center m-3 RegularMadeTommy'>
                                <FormattedMessage id="dashboard.nps.error.server.msg" />
                            </h5>
                        </>
                        :
                        nps > 100 || nps < -100 ?
                            <>
                                <h2 className='text-center RegularMadeTommy' style={{ color: "orange" }}>
                                    Advertencia
                                </h2>
                                <h5 className='text-center m-3 RegularMadeTommy'>
                                    Hubo un error al momento de calcular el NPS.
                                </h5>
                            </>
                            :
                            <>
                                <PieChart width={300} height={160}>
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={data}
                                        cx={cx}
                                        cy={cy}
                                        innerRadius={iR}
                                        outerRadius={oR}
                                        fill="#8884d8"
                                        stroke="none"
                                        paddingAngle={4} // Permite que los segmentos se superpongan}
                                    >
                                        {data.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={entry.color}
                                                stroke={entry.color} // Elimina bordes extraños en la superposición
                                                cornerRadius={10}
                                            />
                                        ))}
                                    </Pie>
                                    <Legend
                                        iconSize={7}
                                        layout='horizontal'
                                        verticalAlign='top'
                                        iconType='circle'
                                        align='center'
                                        wrapperStyle={{ fontFamily: 'Regular MadeTommy', fontSize: '14px' }}
                                    />
                                    {
                                        !isNaN(nps) && needle(nps, data, cx, cy, iR, oR, '#5c5b5b')
                                    }
                                </PieChart>
                                <h3 className='text-center RegularMadeTommy'>
                                    {
                                        !isNaN(nps) ? nps + "%"
                                            :
                                            <FormattedMessage id="dashboard.no.data" />
                                    }
                                </h3>
                            </>
            }
        </CardElementComponent>
    );
}
