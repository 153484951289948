import React, { useEffect } from 'react'
import { FormattedMessage } from "react-intl";
import LockResetIcon from '@mui/icons-material/LockReset';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import MetaDecorator from '../UtilsComponents/MetaDecorator';
import { ChangeDefCustomer } from '../SettingsComponents/ChangeDefCustomer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ChangePassword } from '../AuthComponents/ChangePassword';

export const Settings = () => {

    useEffect(() => {
        document.body.style.backgroundColor = "#f2f1f7";
        // eslint-disable-next-line
    }, [])

    const handleClick = async (e) => {
        const id = e.target.href.split('#')[1]
        //Get the active in the list
        const active = document.getElementsByClassName('list-group-item active')[0]
        active.classList.remove('active')
        document.getElementsByClassName('tab-pane show')[0].classList.remove('active')
        document.getElementsByClassName('tab-pane show')[0].classList.remove('show')
        // add the active class to the clicked element given the id
        document.getElementById(id).classList.add('show')
        document.getElementById(id).classList.add('active')
        // add the active class to list item
        e.target.classList.add('active')
    }

    return (
        <div className="container light-style flex-grow-1 container-p-y mb-3">
            <MetaDecorator
                title={`Configuración`}
                description={`Configuración 7Bytes`}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={`Configuración 7Bytes`}
                path={window.location.pathname}
            />
            <div className="card overflow-hidden" style={{ borderRadius: "10px" }}>
                <h4 className="font-weight-bold py-3 mx-2" >
                    <strong>
                        <SettingsIcon /> <FormattedMessage id="settings.title" />
                    </strong>
                </h4>
                <div className="row no-gutters row-bordered row-border-light">
                    <div className="col-md-3 pt-0">
                        <div className="list-group list-group-flush account-settings-links">
                            {/* <a className="list-group-item list-group-item-action " onClick={handleClick} href="#account-general">
                                <AdminPanelSettingsIcon /> <FormattedMessage id="settings.general.profile" />
                            </a> */}
                            <a className="list-group-item list-group-item-action active" onClick={handleClick} href="#account-change-password">
                                <LockResetIcon /> <FormattedMessage id="settings.change.password" />
                            </a>
                            <a className="list-group-item list-group-item-action" onClick={handleClick} href="#change-default-customer">
                                <ManageAccountsIcon /> <FormattedMessage id="settings.change.default.customer" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content">
                            {/* <div className="tab-pane fade active show" id="account-general">
                                <div className="card-body media align-items-center">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" className="d-block ui-w-80" />
                                    <div className="media-body ml-4">
                                        <label className="btn btn-outline-primary mt-2">
                                            Upload new photo
                                            <input type="file" className="account-settings-fileinput" />
                                        </label> &nbsp;
                                        <button type="button" className="btn btn-default md-btn-flat">Reset</button>
                                        <div className="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
                                    </div>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body">
                                    <div className="form-group">
                                        <label className="form-label">Username</label>
                                        <input type="text" className="form-control mb-1" defaultValue="nmaxwell" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <input type="text" className="form-control" defaultValue="Nelle Maxwell" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">E-mail</label>
                                        <input type="text" className="form-control mb-1" defaultValue="nmaxwell@mail.com" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Company</label>
                                        <input type="text" className="form-control" defaultValue="Company Ltd." />
                                    </div>
                                </div>
                            </div> */}
                            <div className="tab-pane fade active show" id="account-change-password">
                                <ChangePassword />
                            </div>
                            <div className="tab-pane fade " id="change-default-customer">
                                <ChangeDefCustomer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}