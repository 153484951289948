import { Box, TextField } from '@mui/material'
import React from 'react'
import { useSurveyContext } from '../../Context/SurveyCustomHooks/useSurveyContext';
import { FormattedMessage, useIntl } from 'react-intl';


export const PersonalInfo = () => {
    const intl = useIntl()
    const {
        surveyInfo,
        personalInfo,
        isSmallScreen,
        setPersonalInfo,
        personalInfoErrors,
    } = useSurveyContext();

    const handleOnChange = (e) => {
        setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
        // setPersonalInfoErrors({ ...personalInfoErrors, [e.target.name]: '' });
    }

    return (
        <Box>
            <h3 className='MediumMadeTommy' style={{ textAlign: isSmallScreen ? "left" : "center" }}>
            <FormattedMessage
                    id="amonymous.survey.title"
                />
                &nbsp;
                {surveyInfo?.requiredPersonalInfo && <span style={{ color: "red" }}>*</span>}
            </h3>
            <h5 className='LightMadeTommy'>
                <FormattedMessage
                    id="amonymous.survey.description"
                />
            </h5>
            <div className='row'>
                <div className='col-md-7'>
                    <TextField
                        className='RegularMadeTommy mt-3'
                        error={personalInfoErrors.name !== ''}
                        helperText={personalInfoErrors.name}
                        id="name"
                        name='name'
                        label={intl.formatMessage({ id: "amonymous.survey.name" })}
                        placeholder={intl.formatMessage({ id: "amonymous.survey.name" })}
                        fullWidth
                        value={personalInfo.name}
                        onChange={handleOnChange}
                    // sx={{ width: isSmallScreen ? '100%' : '60%' }}
                    />
                </div>
                <div className='col-md-5'>
                    <TextField
                        className='RegularMadeTommy mt-3'
                        error={personalInfoErrors.Id !== ''}
                        helperText={personalInfoErrors.Id}
                        id="Id"
                        name='Id'
                        label={intl.formatMessage({ id: "amonymous.survey.id" })}
                        placeholder={intl.formatMessage({ id: "amonymous.survey.title.id" })}
                        fullWidth
                        // sx={{ width: isSmallScreen ? '100%' : '30%' }}
                        type='number'
                        inputProps={{ min: 111111111, max: 9999999999 }}
                        value={personalInfo.Id}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-7'>
                    <TextField
                        className='RegularMadeTommy mt-3'
                        error={personalInfoErrors.correo !== ''}
                        helperText={personalInfoErrors.correo}
                        id="correo"
                        name='correo'
                        label={intl.formatMessage({ id: "amonymous.survey.email" })}
                        placeholder={intl.formatMessage({ id: "amonymous.survey.title.email" })}
                        fullWidth
                        type='email'
                        value={personalInfo.correo}
                        onChange={handleOnChange}
                    />
                </div>
                <div className='col-md-5'>
                    <TextField
                        className='RegularMadeTommy mt-3'
                        error={personalInfoErrors.phone !== ''}
                        helperText={personalInfoErrors.phone}
                        id="phone"
                        name='phone'
                        label={intl.formatMessage({ id: "amonymous.survey.phone" })}
                        placeholder={intl.formatMessage({ id: "amonymous.survey.title.phone" })}
                        type='number'
                        fullWidth
                        inputProps={{ min: 3000000000 }}
                        value={personalInfo.phone}
                        onChange={handleOnChange}
                    />
                </div>
            </div>
        </Box>
    );
};

