import React, { useEffect, useState } from 'react'
import { Layer, Rectangle, Sankey } from 'recharts'
import { CardElementComponent } from '../UtilsComponents/CardElementComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import { Skeleton } from '@mui/material';
import { useResizeChart } from './ResizeCharts';

const MyCustomNode = ({
    x,
    y,
    width,
    height,
    index,
    payload,
    containerWidth,
}) => {
    const isOut = x + width + 6 > containerWidth;
    return (
        <Layer key={`CustomNode${index}`}>
            <Rectangle
                x={x}
                y={y}
                width={width}
                height={height}
                fill="#a197f8"
                fillOpacity="1"
            />
            <text
                textAnchor={isOut ? 'end' : 'start'}
                x={isOut ? x - 6 : x + width + 6}
                y={y + height / 2}
                fontSize="14"
                stroke="#333"
            >
                {payload.name}
            </text>
            <text
                textAnchor={isOut ? 'end' : 'start'}
                x={isOut ? x - 6 : x + width + 6}
                y={y + height / 2 + 13}
                fontSize="12"
                stroke="#333"
                strokeOpacity="0.5"
            >
                {`${payload.value}`}
            </text>
        </Layer>
    );
};

export const SurveyNumberSankeyChart = ({ data, isLoading, isError, error, chartName }) => {
    // const matches = useMediaQuery('(min-width:1000px)');
    const intl = useIntl();

    const Width = useResizeChart(chartName);
        const [chartWidth, setChartWidth] = useState(Width);
    
        const getWidth = () => {
            const cardWith = document.getElementById(chartName);
            if (cardWith) {
                return cardWith.clientWidth;
            }
            return 0;
        }
    
        useEffect(() => {
            setChartWidth(Width);
        }, [Width]);
    
        useEffect(() => {
            const resultWidth = getWidth();
            setChartWidth(resultWidth);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isLoading]);
        
    // Function to sum values, excluding surveyTotal
    const sumValues = (obj) => {
        let total = 0;
        for (let key in obj) {
            if (key !== "surveyTotal" && key !== "sentSurveyTotal") {
                total += obj[key];
            }
        }
        return total;
    };

    const nodes = [
        {
            "name": "Total"
        },
        ...(sumValues(data) - data?.errors > 0 ? [{
            "name": intl.formatMessage({ id: "dashboard.Assigned" })
        }] : []),
        ...(data?.opened > 0 ? [
            {
                "name": intl.formatMessage({ id: "dashboard.opened" })
            }] : []),
        ...(data?.completed > 0 ? [
            {
                "name": intl.formatMessage({ id: "dashboard.completed" })
            }] : []),
        ...(data?.sent > 0 ? [
            {
                "name": intl.formatMessage({ id: "dashboard.sent" })
            }
        ] : []),
        ...(data?.errors > 0 ? [
            {
                "name": intl.formatMessage({ id: "dashboard.sending.error" })
            }
        ] : [])
    ]

    const dataNodes = {
        nodes,
        "links": [
            ...(data?.opened > 0 ? [
                {
                    "source": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.Assigned" })),
                    "target": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.opened" })),
                    "value": data?.opened
                }
            ] : []),
            ...(data?.completed > 0 ? [
                {
                    "source": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.Assigned" })),
                    "target": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.completed" })),
                    "value": data?.completed
                }
            ] : []),
            ...(data?.sent > 0 ? [
                {
                    "source": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.Assigned" })),
                    "target": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.sent" })),
                    "value": data?.sent
                }
            ] : []),
            ...(sumValues(data) - data?.errors > 0 ? [{
                "source": nodes.findIndex(node => node.name === "Total"),
                "target": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.Assigned" })),
                "value": sumValues(data) - data?.errors
            }] : []),
            ...(data?.errors > 0 ? [
                {
                    "source": nodes.findIndex(node => node.name === "Total"),
                    "target": nodes.findIndex(node => node.name === intl.formatMessage({ id: "dashboard.sending.error" })),
                    "value": data.errors
                }
            ] : []),
        ]
    };

    return (
        <CardElementComponent sx={{ height: "246px" }} >
            <h3 className='text-center MediumMadeTommy' style={{ color: "#2b364a" }}>
                <FormattedMessage id="dashboard.survey.by.status" />
            </h3>
            {
                (isLoading) ?
                    <Skeleton animation="wave" variant="rounded" height={186} width={chartWidth - 50} />
                    :
                    isError ?
                        error?.response?.status === 404 ?
                            <h3 className='text-center mt-3 RegularMadeTommy' style={{ color: "orange" }} >
                                <FormattedMessage id="dashboard.no.data" />
                            </h3>
                            :
                            <h3 className='text-center mt-3 RegularMadeTommy' style={{ color: "red" }}  >
                                <FormattedMessage id="dashboard.survey.info.error" />
                            </h3>
                        :
                        <>
                            {sumValues(data) === 0 ?
                                <h3 className='text-center mt-3 RegularMadeTommy'>
                                    <FormattedMessage id="dashboard.no.data" />
                                </h3>
                                :
                                <Sankey
                                    className= "LightMadeTommy"
                                    width={chartWidth}
                                    height={200}
                                    data={dataNodes}
                                    node={<MyCustomNode />}
                                    nodePadding={42}
                                    nodeWidth={5}
                                    linkCurvature={0.3}
                                    iterations={100}
                                    margin={{
                                        left: 30,
                                        right: 120,
                                        top: 20,
                                        bottom: 20,
                                    }}
                                    sort={false}
                                    link={{ stroke: '#a197f7',   }}
                                >
                                    {/* <Tooltip isAnimationActive='false' /> */}
                                </Sankey>
                            }
                        </>
            }
        </CardElementComponent >
    )
}
